body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

#autocomplete-wrapper{
	text-align: center;
}

#autocomplete-wrapper input{
	padding-left: 40px;
}

header{
	padding: 36px 0;
	background-color: #333;
	border-bottom: 1px solid #ddd;
	box-shadow: 0 4px 12px rgba(0, 0, 0, .02);
	position: relative;
}

#page-progress{
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-progress-item{
	margin: 0 20px;
	color: #aaa;
}

.page-progress-item.active{
	font-weight: 700;
	color: #fff;
}

#progress-bar-wrapper, #progress-bar{
	height: 6px;
}

#progress-bar-wrapper{
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

#progress-bar{
	transition: width .4s ease-out;
	background-color: #0d6efd;
}

.main-body{
	max-width: 800px;
	margin: 0 auto;
	padding: 64px 44px;
}

.access-smt-main-body{
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: #fff;
	max-width: 100%;
	width: 60%;
	margin: 0 auto;
	padding: 88px 44px 0;
	z-index: 10;
}

.form-control.is-valid, .was-validated .form-control:valid{
    border-color: #ced4da;
    background-image: none;
}

#agreement-wrapper{
	border: 1px solid #ddd;
	padding: 4px 24px;
    max-height: 700px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

#agreement-wrapper table,#agreement-wrapper td{
	position: static;
}

.form-group{
	margin: 20px 0;
}

.page-wrapper{
	position: relative;
}

.page-wrapper>div{
	position: absolute;
	width: 100%;
}

#plans-wrapper{
	display: flex;
	/* flex-direction: column; */
}

#plans-aside{
	width: 520px;
	padding: 24px 32px;
	flex-shrink: 0;
}

#plans-list{
	padding: 24px 32px;
	flex: 1;
	background-color: #fafafa;
}

.plans-filter-options{
	display: flex;
	flex-wrap: wrap;
}

.plan-row{
	display: flex;
	background-color: #fff;
	border: 2px solid #6c757d;
	border-radius: 8px;
	padding: 24px 16px;
	margin: 20px 0;
}

.checkbox-item{
	display: flex;
	align-items: center;
	padding: 4px 12px;
	cursor: pointer;
	margin: 8px 0;
}

.checkbox-item{
	flex: 0 0 50%;
}

.checkbox-item span{
	margin-left: 8px;
	color: #666;
}

.checkbox-item.onlyread{
	opacity: .4;
	cursor: default;
}

.plans-filter{
	margin-bottom: 32px;
}

.plans-filter-title{
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 8px;
}

.rep-logo{
	width: 80px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	background-color: #333;
	color: #fff;
	font-weight: 700;
}

.plan-overview{
	display: flex;
	align-items: center;
}

.plan-info{
	flex: 1;
	text-align: center;
}

.plan-info>div{
	font-size: 20px;
	font-weight: 700;
}

.plan-info label{
	font-size: 14px;
	color: #666;
}

.plan-content{
	flex: 1;
}

.plan-options{
	width: 200px;
	flex-shrink: 0;
	border-left: 2px solid #6c757d;
}

.plan-name{
	font-size: 17px;
	font-weight: 700;
}

.plan-info .plan-est-rate{
	font-size: 28px;
}

.plan-links{
	display: flex;
}

.plan-links a{
	display: block;
	margin-right: 24px;
	color: #666;
}

.plan-detail{
	max-height: 0;
	overflow: hidden;
}

.plan-detail.active{
	max-height: 9999px;
}

.plan-enroll-btn{
	font-weight: 700;	
}

.mask{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .4);
}

.usage-adjust-row{
	display: flex;
	flex-wrap: wrap;
}

.usage-adjust-item{
	flex: 1;
}

.usage-adjust-item .form-label{
	width: 100%;
	text-align: center;
	font-size: 13px;
	font-weight: 500;
}

.usage-adjust-item .form-control{
	padding: 16px 4px;
	text-align: center;
    font-size: 14px;
}

.usage-adjust-section{
	background-color: #fff;
	border: 2px solid #333;
	border-radius: 8px;
	padding: 0 20px;
}

.premise-detail-row{
	margin-top: 12px;
	padding: 12px 16px;
	background-color: #fafafa;
}

.premise-detail{
	display: flex;
}

.premise-detail-item{
	font-size: 14px;
	color: #666;
	font-weight: 500;
	margin: 4px 0;
	flex: 1;
}

.checkbox-row .checkbox-item{
	align-items: flex-start;
}

.checkbox-row .checkbox-item span{
	position: relative;
	top: -2px;
    margin-left: 12px;
}